import React, { Component } from "react";
import FormErrors from './formErrors'
import KeyCodeForm from './keyCodeForm'

import "../../assets/styles/components/form.css";
import "flatpickr/dist/themes/material_blue.css";

class Form extends Component {
    constructor (props) {
        super(props);
            
        this.state = {
            fields: {
                name: '',
                date: ''
            },
            errors: {},
            obituaryFormValid: false
        }

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }

    handleValidation() {
        const correctName = ['Benjamin Strange', 'benjamin strange', 'BENJAMIN STRANGE', 'benjamin Strange', 'Benjamin strange'],
            correctDate = ['1931-10-18', '18-10-1931', '18/10/1931'],
            dateRegex = new RegExp(/^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/);

        let fields = this.state.fields,
            errors = {},
            nameField = fields["name"],
            dateField = fields["date"],
            formIsValid = true;

        if (nameField === '') {
            formIsValid = false;
            errors["name"] = "No record found";
        }    

        if (dateField === '') {
            formIsValid = false;
            errors["name"] = "No record found";
        }    

        if (!correctDate.includes(dateField)) {
            formIsValid = false;
            errors["name"] = "No record found";
        }

        if (!correctDate.includes(dateField) && !dateRegex.test(dateField)) {
            formIsValid = false;
            errors["name"] = "No record found";
        }

        if (nameField !== '' && !correctName.includes(nameField) && dateField !== '' && correctDate.includes(dateField)) {
            formIsValid = false;
            errors["name"] = "No record found";
        } else if (nameField !== '' && correctName.includes(nameField) && dateField !== '' && !correctDate.includes(dateField)) {
            formIsValid = false;
            errors["name"] = "No record found";
        } else if (nameField !== '' && !correctName.includes(nameField) && dateField !== '' && !correctDate.includes(dateField)) {
            formIsValid = false;
            errors["name"] = "No record found";
        } 

        this.setState({ errors: errors });
        
        return formIsValid;
    }

    handleDateInput(e) {
        e.target.value = e.target.value.replace(/(\d{2})(\d{2})(\d{4})/, "$1/$2/$3");
    }

    handleUserInput(e){    		
        let fields = this.state.fields;

        if (typeof e.target !== 'undefined') {
            fields[e.target.name] = e.target.value;
        } else {
        let currentDate = new Date(e),
            formattedDate = currentDate.getFullYear() + '-' + (currentDate.getMonth() + 1) + '-' + currentDate.getDate();
            fields['date'] = formattedDate;
        }

        this.setState({ fields });
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            this.setState({ obituaryFormValid: true })
            return true;
        } else {
            this.setState({ obituaryFormValid: false })
            return false;
        }
    }

    render() {
        const { obituaryFormValid } = this.state;

        if (!obituaryFormValid) {
            return (
                <>
                <p>This is an online archive of obituaries of staff and notable students of Cambridge University and related institutions. The articles have been collected and scanned from local and national newspapers.</p>
                <p>This is a public resource and the majority of items are available for all to view, however certain records are classified and password protected. Should you have difficulty obtaining a password, please visit Professor Nadia Kamal at Gonville and Caius College at her office at the top of staircase Y on the fourth Wednesday of the month between 2 and 3pm (please note the staircase is currently out of order).</p>
                <form className={`form ${!obituaryFormValid ? 'has-error' : ''}`} onSubmit={(e) => this.handleFormSubmit(e)} noValidate>
                    <h2>Search Obituaries</h2>
                    <div className="form-group">
                        <label htmlFor="name">Name:</label>
                        <input type="text" required className="form-control" name="name" aria-label="Name" onChange={this.handleUserInput} />
                    </div>
                    <div className={`form-group`}>
                        <label htmlFor="date">Date of Death:<span className="small">(dd/mm/yyyy)</span></label>
                        <input type="text" className="form-control" name="date" onInput={this.handleDateInput} aria-label="Date" inputMode="numeric" pattern="\d{1,2}/\d{1,2}/\d{4}" onChange={this.handleUserInput} maxLength="10" />
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="button">Search</button>
                        <FormErrors formErrors={this.state.errors} />
                    </div>
                </form>
                </>
            )
        } else {
            return (
                <KeyCodeForm />
            )
        }
    }
}
export default Form;