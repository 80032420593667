import React, { Component } from "react";

import ObituaryForm from "./form"
import ObituaryImage from "../obituaryImage"
import FormErrors from "./formErrors"
import PointTarget from "react-point"

class CalculatorKey extends Component {
  render() {
    const { onPress, className, ...props } = this.props
    
    return (
      <PointTarget onPoint={onPress}>
        <div className={`calculator-key ${className}`} {...props}/>
      </PointTarget>
    )
  }
}

class KeyCodeForm extends Component {
    constructor (props) {
        super(props);
            
        this.state = {
            fields: {
                password: ''
            },
            errors: {},
            passwordCodeFormValid: false,
            backToForm: false,
            value: null,
            displayValue: '',
            operator: null,
            waitingForOperand: false
        }

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleBackButton = this.handleBackButton.bind(this);
    }

    clearAll() {
        this.setState({
            fields: {
                password: ''
            },
            errors: {},
            passwordCodeFormValid: false,
            backToForm: false,
            value: null,
            displayValue: '',
            operator: null,
            waitingForOperand: false
        })
    }

    inputDigit(digit) {
        const { displayValue, waitingForOperand } = this.state

        if (waitingForOperand) {
            this.setState({
                displayValue: String(digit),
                waitingForOperand: false
            })
        } else {
            this.setState({
                displayValue: displayValue === '' ? String(digit) : displayValue + digit
            })
        }
    }

    handleValidation() {
        const correctPassword = ['51967'];

        let errors = {},
            formIsValid = true;
                        
        if (this.state.displayValue === '') {
            formIsValid = false;
            errors["password"] = "Please enter a key";  
        } else if (this.state.displayValue !== '' && !correctPassword.includes(this.state.displayValue)) {
            formIsValid = false;
            errors["password"] = "Incorrect key";
        }

        this.setState({ errors: errors });

        return formIsValid;
    }

    handleFormSubmit(e) {
        e.preventDefault();

        if (this.handleValidation()) {
            this.setState({ passwordCodeFormValid: true })
            return true;
        } else {
            this.setState({ passwordCodeFormValid: false })
            return false;
        }
    }

    handleBackButton(e) {
        e.preventDefault();
        
        this.setState({ backToForm: true });
    }

    render() {
        const { passwordCodeFormValid, backToForm, displayValue } = this.state
        const clearText = 'Clear'

        if (!passwordCodeFormValid && !backToForm) {
            return (
                <>
                <div className="align--left"><button aria-label="Back" onClick={(e) => this.handleBackButton(e) }>&lt; back</button></div>
                <h2 className="disabled align--left"><span>File Found:</span>Benjamin Strange<span>D.O.D 18 OCT 1931</span></h2>
                <form className={`form form__keycode ${!passwordCodeFormValid ? 'has-error' : ''}`} noValidate>
                    <h2>This Record is Locked</h2>
                    <div className="form-group">
                        <label htmlFor="name">Password:</label>
                        <input type="password" required className="form-control" name="password" aria-label="Password" value={displayValue} readOnly />
                        <div className="calculator">
                            <div className="calculator-keypad">
                                <div className="input-keys">
                                    <div className="digit-keys">
                                        <CalculatorKey className="key-clear" onPress={() => this.clearAll()}>{clearText}</CalculatorKey>
                                        <CalculatorKey className="key-0" onPress={() => this.inputDigit(0)}>0</CalculatorKey>
                                        <CalculatorKey className="key-1" onPress={() => this.inputDigit(1)}>1</CalculatorKey>
                                        <CalculatorKey className="key-2" onPress={() => this.inputDigit(2)}>2</CalculatorKey>
                                        <CalculatorKey className="key-3" onPress={() => this.inputDigit(3)}>3</CalculatorKey>
                                        <CalculatorKey className="key-4" onPress={() => this.inputDigit(4)}>4</CalculatorKey>
                                        <CalculatorKey className="key-5" onPress={() => this.inputDigit(5)}>5</CalculatorKey>
                                        <CalculatorKey className="key-6" onPress={() => this.inputDigit(6)}>6</CalculatorKey>
                                        <CalculatorKey className="key-7" onPress={() => this.inputDigit(7)}>7</CalculatorKey>
                                        <CalculatorKey className="key-8" onPress={() => this.inputDigit(8)}>8</CalculatorKey>
                                        <CalculatorKey className="key-9" onPress={() => this.inputDigit(9)}>9</CalculatorKey>
                                    </div>
                                </div>
                            </div>
                        </div>                            
                    </div>
                    <div className="form-actions">
                        <button type="submit" className="button button--inverted" onClick={(e) => this.handleFormSubmit(e)}>Enter</button>
                        <FormErrors formErrors={this.state.errors} />
                    </div>
                    <button aria-label="Close Form" className="close" onClick={(e) => this.handleBackButton(e)}>x</button>
                </form>
                </>
            )
        } else if (passwordCodeFormValid && !backToForm) {
            return (
                <>
                    <div className="align--left"><button aria-label="Back" onClick={(e) => this.handleBackButton(e)}>&lt; back</button></div>
                    <h2 className="align--left"><span>File Found:</span>Benjamin Strange<span>D.O.D 18 OCT 1931</span></h2>
                    <ObituaryImage />
                </>
            )
        } else {
            return (
                <ObituaryForm />
            )
        }
    }
}
export default KeyCodeForm;